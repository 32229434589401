<template>
	<!-- 分销设置 -->
	<div class="goods-distribution-set">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
			<a-form-item label="商品分销">
				<a-radio-group v-model:value="disForm.is_distribution">
					<a-radio :value="1">开启</a-radio>
					<a-radio :value="0">不开启</a-radio>
				</a-radio-group>
			</a-form-item>
			<template v-if='disForm.is_distribution==1'>
				<a-form-item label="分销奖励方式">
					<a-radio-group v-model:value="disForm.dis_price_type">
						<a-radio :value="1">支付金额百分比</a-radio>
						<a-radio :value="2">固定金额</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="一级分销奖励">
					<a-input v-model:value="disForm.one_dis_price" :addon-after="disForm.dis_price_type == 1 ? '%':'元'"></a-input>
				</a-form-item>
				<a-form-item label="二级分销奖励">
					<a-input v-model:value="disForm.two_dis_price" :addon-after="disForm.dis_price_type == 1 ? '%':'元'"></a-input>
				</a-form-item>
				<a-form-item label="一级团队奖励">
					<a-input v-model:value="disForm.one_team_price" :addon-after="disForm.dis_price_type == 1 ? '%':'元'"></a-input>
				</a-form-item>
				<a-form-item label="二级团队奖励">
					<a-input v-model:value="disForm.two_team_price" :addon-after="disForm.dis_price_type == 1 ? '%':'元'"></a-input>
				</a-form-item>
			</template>
		</a-form>
	</div>
</template>

<script>
export default{
	name:"com-distribution-set",
	data(){
		return{
			disForm:{
				is_distribution:0,		//是否开启分销
				one_dis_price:"",
				two_dis_price:"",
				one_team_price:"",
				two_team_price:"",
				dis_price_type:1
			}
		}
	},
	props:{
		disInfo:{	//分销信息
			type:[Array,Object],
		}
	},
	watch:{
		disForm:{
			deep: true,
			immediate: true,	//首次传入的时候也要执行此监听函数
			handler(val){
				this.$emit("changeDis",val)
			}
		},
		disInfo:{
			deep: true,
			immediate: true,	//首次传入的时候也要执行此监听函数
			handler(val){
				this.setForm()
			}
		}
	},
	mounted() {
		this.setForm()
	},
	methods:{
		setForm(){
			let t = this
			if( t.disInfo ){
				t.disForm.is_distribution = parseInt(t.disInfo.is_distribution) || 0
				t.disForm.one_dis_price = t.disInfo.one_dis_price || ""
				t.disForm.two_dis_price = t.disInfo.two_dis_price || ""
				t.disForm.one_team_price = t.disInfo.one_team_price  || ""
				t.disForm.two_team_price = t.disInfo.two_team_price  || ""
				t.disForm.dis_price_type = t.disInfo.dis_price_type  || 1
			}
		},
	}
}
</script>

<style>
</style>
