<template>
	<div id="goodsEdit" class="el-content">
		<a-tabs v-model:activeKey="activeName">
			<a-tab-pane key="first" tab="基本信息">
				<a-form :label-col="{span:2}" :wrapper-col="{span:12}">
					<a-card title="分类选择" class="mb12">
						<a-form-item v-if="!id">
							<template #label>
								<span>商品ID</span>
								<a-tooltip title="填写id获取到信息后,将展示这条信息的所有信息,点击保存将复制一条数据;方便快速添加产品信息">
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="copy_id">
								<template #addonAfter>
									<div @click="getGoodsDetail(copy_id,true)">获取</div>
								</template>
							</a-input>
						</a-form-item>
						<a-form-item label="商品分类" >
							<a-select style="width: 300px;" v-model:value="parent_id" placeholder="请选择一级类目" @change="changeParentType">
							    <a-select-option
							      v-for="(item,index) in typeData"
							      :key="index"
							      :value="item.id">{{item.name}}
							    </a-select-option>
							</a-select>
							<a-select mode="multiple" style="width: 300px;" v-model:value="child_id" placeholder="请选择二级类目">
							    <a-select-option
									v-for="(item,index) in typeTwoData"
									:key="index"
									:value="item.id">
									{{item.name}}
							    </a-select-option>
							</a-select>
							<div style="font-size: 12px;color: #999;">类目多选仅能选择同一 一级类目下的多个分类，不能选择多个一级类目下的多个类目;不选二级类目,则默认选择一级类目下的全部二级类目</div>
						</a-form-item>
					</a-card>
					<a-card title="基本信息" class="mb12">
						<a-form-item label="商品名称" name="goods_name">
							<a-input v-model:value="form.goods_name"></a-input>
						</a-form-item>
						<a-form-item label="商品简单介绍">
							<a-input v-model:value="form.goods_remark"></a-input>
						</a-form-item>
						<a-form-item label="商品重量">
							<a-input v-model:value="form.weight" addon-after="克"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>轮播图</span>
								<a-tooltip title="轮播图第一张为商品封面图,拖动可排序!">
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<kd-img-select :mutil="true" :src="form.goods_slide" @change="(url)=>{ form.goods_slide = url}"></kd-img-select>
						</a-form-item>
						
						<a-form-item label="商品视频展示">
							<com-select-video v-model="form.goods_video_src"></com-select-video>	
						</a-form-item>
						
						<a-form-item label="是否上架" v-if="isShop==0">
							<a-radio-group v-model:value="form.is_put_away">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="是否推荐" v-if="isShop==0">
							<a-radio-group v-model:value="form.is_recommend">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="排序">
							<a-input v-model:value="form.rank"></a-input>
						</a-form-item>
					</a-card>
					
					<a-card title="价格设置" class="mb12">
						<div>
							<a-form-item label="现价">
								<a-input v-model:value="form.price" addon-after="元" type="number"></a-input>
							</a-form-item>
							<a-form-item>
								<template #label>
									<span>原价</span>
									<a-tooltip title="以划线形式展示"><i class="ri-information-fill"></i></a-tooltip>
								</template>
								<a-input v-model:value="form.old_price" addon-after="元"></a-input>
							</a-form-item>
							<a-form-item v-if="isShop==0 && shop_id == 0">
								<template #label>
									<span>是否开启批发</span>
									<a-tooltip title="开启后,多商户以批发价对商品进行购买/进货"><i class="ri-information-fill"></i></a-tooltip>
								</template>
								<a-radio-group v-model:value="form.is_purchase" >
									<a-radio :value="1">是</a-radio>
									<a-radio :value="0">否</a-radio>
								</a-radio-group>
							</a-form-item>
							<a-form-item label="批发价" v-if="form.is_purchase==1 && isShop==0 && shop_id == 0">
								<a-input v-model:value="form.wholesale_price" addon-after="元"></a-input>
							</a-form-item>
						</div>
						<a-form-item label="积分价格" v-if='form.goods_type==2'>
							<a-input v-model:value="form.score_price" addon-after="积分"></a-input>
						</a-form-item>
					</a-card>
					
					<a-card title="配送设置" class="mb12">
						<a-form-item label="配送方式" v-if="isShop==0 && shop_id == 0">
							<a-radio-group v-model:value="form.send_type" @change="changeSendType">
								<a-radio :value="1">快递</a-radio>
								<a-radio :value="2">自提</a-radio>
								<a-radio :value="3">快递和自提</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>地址经纬度</span>
								<a-tooltip title="用于计算用户与该商品的位置距离" ><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<kd-map :value="form.longitude+','+form.latitude" @change="getAddress"></kd-map>
						</a-form-item>
						<a-form-item label="选择自提门店" v-if="form.send_type !=1 &&$store.state.modulesPerm.salesroom == 1">
							<a-select v-model:value="form.outlet_class" placeholder="请选择">
								<a-select-option v-for="(item,index) in outletData" :key="index" :value="''+item.id">{{item.name}}</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="运费模板">
						    <a-radio-group v-model:value="form.set_send_price_type">
								<a-radio :value="1">固定设置运费</a-radio>
								<a-radio :value="2">运费模板</a-radio>
						    </a-radio-group>
						</a-form-item>
						
						<a-form-item label="运费" v-if="form.send_type !=2 && form.set_send_price_type == 1">
							<template #label>
								<span>运费</span>
								<a-tooltip title="注意:设置该运费后将使用该运费金额对订单总价进行计算,不设置将使用默认的运费规则进行订单总价计算"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="form.send_price" addon-after="元"></a-input>
						</a-form-item>
					</a-card>
					<a-card title="营销设置" class="mb12">
						<a-form-item v-if="isShop==0 && shop_id == 0">
							<template #label>
								<span>虚拟销量</span>
								<a-tooltip content="展示销量 = 虚拟销量+真实销量" placement="top"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="form.sale_count" addon-after="件"></a-input>
						</a-form-item>
						<a-form-item  v-if="isShop==0 && shop_id == 0">
							<template #label>
								<span>是否参与会员折扣</span>
								<a-tooltip title="开启后,用户可根据用户等级折扣购买该商品" placement="top"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-radio-group v-model:value="form.is_user_discount">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item v-if="isShop==0 && shop_id == 0">
							<template #label>
								<span>购买赠送积分</span>
								<a-tooltip title="用户购买该商品可获得的积分数量"><i class="ri-information-fill"></i></a-tooltip>
							</template>
							<a-input v-model:value="form.score"></a-input>
						</a-form-item>
						<a-form-item label="商品服务">
							<a-checkbox-group v-model:value="form.service_id">
								<a-checkbox v-for="(item,index) in serviceData" :key="index" :value="item.id" name="type">{{item.name}}</a-checkbox>
							</a-checkbox-group>
						</a-form-item>		
					</a-card>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="distribution" tab="分销设置" v-if="isShop == 0">
				<com-distribution-set @changeDis="getDisData"
					:dis-info="{
						one_dis_price:form.one_dis_price,
						two_dis_price:form.two_dis_price,
						one_team_price:form.one_team_price,
						two_team_price:form.two_team_price,
						dis_price_type:form.dis_price_type,
						is_distribution:form.is_distribution,
					}" >
				</com-distribution-set>
			</a-tab-pane>
			<a-tab-pane key="second" tab="商品规格">
				<a-form ref="form1" :model="form"  :label-col="{span:2}" :wrapper-col="{span:12}">
					<a-form-item label="商品库存">
						<a-input v-model:value="form.count" v-if="form.is_open_sku==0"></a-input>
						<a-input v-model:value="form.count" v-if="form.is_open_sku==1" disabled></a-input>
					</a-form-item>
					
					<a-form-item label="是否使用规格">
						<a-radio-group v-model:value="form.is_open_sku">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>
					
					<a-form-item label="规格和规格值" v-if="form.is_open_sku">
						<a-input placeholder="请输入内容" v-model:value="spec_name" addon-before="规格组">
							<template #addonAfter>
								<div @click="addSpecItem">添加</div>
							</template>
						</a-input>
					</a-form-item>
					
					<a-form-item v-if="form.is_open_sku">
						<div class="demo-block" v-for="(item,index) in specData" :key="index">
							<div style="margin-bottom: 10px;">
								<div class="good-tag" @click="deleteSpec(index,item.spec_id)">
									{{item.name}}
									<i class="ri-close-line"></i>
								</div>
								<!-- <a-tag closable type="info" @close="deleteSpec(index,item.spec_id)">{{item.name}}</a-tag> -->
							</div>
							<div v-for="(val,ind) in item.spec_value" 
								:key="ind" 
								class="good-tag" 
								@click="deleteSpecVal(item.spec_id,val.id)">
								{{val.spec_value}}
								<i class="ri-close-line"></i>
							</div>
							<!-- <a-tag closable v-for="(val,ind) in item.spec_value" :key="ind" @close="deleteSpecVal(item.spec_id,val.id)">{{val.spec_value}}</a-tag> -->
							<a-input placeholder="请输入内容" v-model:value="spec_value" addon-before="规格值">
								<template #addonAfter>
									<div @click="addSpecValue(item.spec_id)">添加</div>
								</template>
							</a-input>
						</div>
					</a-form-item>
					<a-form-item label="价格库存" v-if="form.is_open_sku">
						<table>
							<tr>
								<th v-for="(item,item_index) in specData" :key="item_index">{{item.name}}</th>
								<th width="110px">库存</th>
								<th width="110px">价格</th>
								<th width="110px">批发价</th>
								<th width="150px">货号</th>
								<th>规格图片</th>
							</tr>
							<tr v-for="(item,index) in specSku" :key="index">
								<td v-for="(val,ind) in item.sku" :key="ind">{{val.spec_value}}</td>
								<td><a-input type="number" v-model:value="item.count" class="spec-input"></a-input></td>
								<td><a-input v-model:value="item.price" class="spec-input" ></a-input></td>
								<td><a-input v-model:value="item.wholesale_price" class="spec-input" ></a-input></td>
								<td><a-input v-model:value="item.spec_num" class="spec-input" ></a-input></td>
								<td>
									<kd-img-select :src="item.spec_src" :mini="true" @change="(url)=>{ item.spec_src = url }"></kd-img-select>
								</td>
							</tr>
						</table>
					</a-form-item>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="fourth" tab="商品详情">
				<a-form :label-col="{span:2}" :wrapper-col="{span:12}">
					<a-form-item label="商品详情">
						<tinymce-editor v-model="form.goods_desc"></tinymce-editor>
					</a-form-item>
				</a-form>
			</a-tab-pane>
		</a-tabs>
		<a-space style="margin-left: 130px;margin-top: 12px;">
			<a-button type="primary" @click="submitSave">保存</a-button>
			<a-button @click="$router.go(-1)">返回</a-button>
		</a-space>
	</div>
</template>

<script>
import comDistributionSet from '@/components/miniapp/com-distribution-set.vue'
import comSelectVideo from '@/components/public/com-select-video.vue'
import goodsModel from '@/api/goods.js'
import outletModel from '@/api/addons/outlet.js'
import common from '@/api/common.js'
import tool from '@/util/tool.js'
import router from '@/router'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
export default {
	name:'page-goods-edit',
	components:{
		comDistributionSet,
		comSelectVideo
	},
	props:{
		isShop:{
			type:Number //是否为商户 1是 0 否
		}
	},
	setup(){
		const _d = reactive({
			activeName:'first',
			id:0,
			copy_id:'',
			parent_id:'',	//父级分类id
			child_id:[],	//选择的子级分类id
			form:{
				id:0,
				rank:99,
				price:'',
				score:'',
				count:0,
				weight:0,
				outlet:[],		//门店id
				outlet_class:'',//门店分类id
				send_type:1,
				goods_type:1,
				freight_id:0,
				sale_count:0,
				old_price:'',
				cover:'',
				goods_name:'',
				goods_desc:'',
				send_price:'',
				is_assemble:0,
				service_id:[],
				goods_slide:[],
				category_id:[],
				wholesale_price:'',
				is_purchase:0,
				is_open_sku : 0,
				goods_remark:'',
				is_recommend :1,
				is_put_away : 1,
				assemble_price:0,
				assemble_people:0,
				goods_video_src:'',
				is_user_discount:0,
				quota_free_shipping:0,
				piece_free_shipping:0,
				is_distribution:0,
				one_reward_unit:0,
				one_unit_num:'',
				two_reward_unit:0,
				two_unit_num:'',
				total_dis_price:'',	//总分销金额
				one_bonus:'',		//一级奖金
				two_bonus:'',		//二级奖金
				latitude:'',
				longitude:'',
				sys_category_id:0,
				goods_tag:'',
				set_send_price_type:1,
				
				one_dis_price:"",
				two_dis_price:"",
				one_team_price:"",
				two_team_price:"",
				dis_price_type:1
			},
			typeData:[],		//分类
			typeTwoData:[],		//二级类目
			serviceData:[],		//服务
			outletData:[],		//门店
			spec_name:'',
			spec_value:'',
			specData:[],
			specSku:[], 
			shop_id:0,		//用于判断超级管理员是否可以编辑多商户的商品信息
		})
		const opt = useRoute().query
		goodsModel.getCategroyList(1,999,{shop_id:_d.shop_id},res=>{
			_d.typeData = res
			if( opt.id )getGoodsDetail(opt.id,false)
		})
		goodsModel.getGoodsServer(1,999,{shop_id:_d.shop_id},res=>_d.serviceData = res.list)
		outletModel.getOutlet(res=>_d.outletData = res)
		function getGoodsDetail(id,fast){
			goodsModel.getGoodsDetail(id,res=>{
				_d.form = tool.filterForm(Object.keys(_d.form),res)
				if( _d.copy_id ) delete _d.form.id   //存在copyid 则为新增商品
				_d.shop_id = res.shop_id
				setCategoryIds(_d.form.category_id)
				let outlet = []
				res.outlet.forEach(item=>outlet.push(item.id))
				_d.form.outlet = outlet

				if( !_d.form.goods_slide ) res.goods_slide = []
				if( !_d.form.service_id ) res.service_id = []
				//复制的商品信息不能够复制规格信息
				if( fast ) _d.form.is_open_sku = 0
				if( !fast ) {
					_d.specData = res.spec
					_d.specSku = res.goods_spec
				}
			
			})
		}
		function getAddress(e){
			_d.form.latitude = e.latitude
			_d.form.longitude = e.longitude
		}
		
		const changeSendType = (e)=>_d.form.outlet =[]
		const addSpecItem = ()=>{
			goodsModel.addSpecItem(_d.spec_name,res=>{
				_d.specData.push(res)
				_d.spec_name =""
			})
		}

		const addSpecValue = (id)=> goodsModel.addSpecValue(id,_d.spec_value,res=>{
			_d.specData.map(item => {
				if (id == item.spec_id) {
					item.spec_value.push(res);
				}
			})
			_d.spec_value = ""
			getSpecSku()
		})

		function deleteSpecVal(spec_id, id) {
			common.deleteDataList(id,27,'确认也要删除规格信息吗？').then(()=>{
				_d.specData.map((item, index) => {
					if (spec_id == item.spec_id) {
						item.spec_value.map((ops, idx) => {
							if (ops.id == id) {
								item.spec_value.splice(idx, 1);
							}
						})
					}
				})
				getSpecSku();
			})
		}

		function deleteSpec(index,spec_id){
			common.deleteDataList(spec_id,26,'确认要删除规格项吗？').then(()=>{
				_d.specData.splice(index, 1);
				getSpecSku();
			})
		}

		function getSpecSku(){
			goodsModel.getSpecSku(_d.specData,res=>_d.specSku = res)
		}

		function changeParentType(parent_id){
			_d.parent_id = parent_id
			_d.child_id = []
			_d.typeTwoData = []
			_d.typeData.forEach(item=>{
				if( item.id == parent_id ){
					_d.typeTwoData = item.child
				}
			})
		}

		function getCategoryIds(){
			let { parent_id,child_id } = _d , c = ''
			if( !parent_id || parent_id =='' ){
				tool.message('请选择一级类目',"warning")
				return false
			}
			//当没有选择二级分类时，默认选择全部的二级分类
			if( child_id.length == 0 && _d.typeTwoData && _d.typeTwoData.length > 0 ){  
				_d.typeTwoData.forEach(item=>{
					child_id.push(item.id)
				})
			}
			c = parent_id
			if( child_id.length > 0 ){
				c +=','+child_id.join(',')	
			}
			return c
		}

		function setCategoryIds(category_id){
			let arr = category_id.split(','), child_id = []
			if( arr.length > 0 ){
				arr.forEach((item,index)=>{
					if(index == 0 ){
						_d.parent_id = parseInt(item)
					}else{
						child_id.push(parseInt(item))
					}
				})	
			}
			changeParentType(_d.parent_id)
			_d.child_id = child_id
		}

		function getDisData(e){
			_d.form.is_distribution = e.is_distribution
			_d.form.one_dis_price = e.one_dis_price
			_d.form.two_dis_price = e.two_dis_price
			_d.form.one_team_price = e.one_team_price 
			_d.form.two_team_price = e.two_team_price 
			_d.form.dis_price_type = e.dis_price_type
		}

		function submitSave(){
			let { id ,specData,specSku ,serviceData,outletData }=_d;
			let form = JSON.parse(JSON.stringify(_d.form))
			if( id && id!=0 ) form.id = id
			let service_id  = []
			serviceData.map(item=>{
				form.service_id.map(val=>{
					if(item.id == val ){
						service_id.push(item.id)
					}
				})
			})
			form.service_id = service_id
			
			for (let i = 0; i < outletData.length; i++) {
				if( outletData[i].id == form.outlet_class ){
					form.outlet = outletData[i].outlet_id
					break
				}
			}
			//类目数据整理
			form.category_id = getCategoryIds()
			if( !form.category_id ) return
			
			form.cover = form.goods_slide[0]
			let data = {
				goods:form,
				spec:specData,
				goodsSpec:specSku
			}
			if( typeof data.goods.category_id == 'array' || typeof data.goods.category_id =='object'){
				data.goods.category_id = data.goods.category_id.join(',')
			}
			goodsModel.addOrEditGoods(form,specData,specSku,res=>router.go(-1))
		}

		return{
			...toRefs(_d),
			changeSendType,
			addSpecValue,
			deleteSpecVal,
			getSpecSku,
			changeParentType,
			getCategoryIds,
			setCategoryIds,
			getDisData,
			submitSave,
			getAddress,
			addSpecItem,
			getGoodsDetail,
			deleteSpec
		}
	},
}
</script>

<style lang="scss">
	
	.demo-block {
	    border: 1px solid #ebebeb;
	    border-radius: 3px;
	    transition: .2s;
		padding: 20px;
		width: 47%;
		margin-bottom: 5px;
		margin-left: 133px;
	}
	#goodsEdit {
		.box-card{
			margin-top: 10px;
		}
	}
	#goodsEdit table{
		border: 1px solid #F4F4F4;
	}
	#goodsEdit td,#goodsEdit th{
		    vertical-align: top;
			border: 1px solid #eceeef;
			padding: 5px 10px;
	}
	.good-tag{
		font-size: 12px;
		border: 1px solid #d9d9d9;
		display: inline-block;
		height: 24px;
		padding: 0 8px;
		line-height: 22px;
		background: #fafafa;
		border-radius: 4px;
		cursor: pointer;
		margin-right: 8px;

		i {
			position: relative;
			top: 2px;
		}
	}
	
</style>
